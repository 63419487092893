<template>
    <div class="mx-3">
      <h2 class="px-5 py-5">{{ $t('Buffet Card Report') }}</h2>
      <v-row class="px-10 py-1">
        <v-col md="3">
          <v-text-field   :label="$t('hr.time.from-date')" type="date" 
                            v-model="filters.from" clearable>
          </v-text-field>
        </v-col>
        <v-col md="3">
          <v-text-field   :label="$t('hr.time.to-date')" type="date" 
                            v-model="filters.to" clearable>
          </v-text-field>
        </v-col>
        <v-col md="3">
          <v-autocomplete :items="users" item-text="name" item-value="id" v-model="filters.user_id"
                                @keypress="fetchUsers" :loading="usersLoading"
                                :label="$t('user name')" ></v-autocomplete>
        </v-col>
        <v-col md="3">
            <v-autocomplete 
          v-model="filters.student_id"
            :label="$t('student name')"
            clearable :items="childrens" :loading="childrensLoading" color="#757575" item-text="name"
            item-value="id"  @keypress="fetchRegRecordAutoComplete($event)"
            >
          </v-autocomplete>
        </v-col>
        <v-col md="3">
          <v-autocomplete
                color="#757575" 
                :label="$t('type')"
                :items="type"
                item-text="name"
                item-value="value"
                v-model="filters.type"
                
              ></v-autocomplete>
        </v-col>
        <v-col md="3">
          <v-text-field   :label="$t('card number')" type="number" 
                            v-model="filters.buffet_card" clearable>
          </v-text-field>
          </v-col>
          <v-col md="3">
           
          </v-col>
          <v-col md="3">
           
          </v-col>
       

        <v-col md="3" class="mb-3 py-0">
          <v-btn color="green" dark block @click="getAll(1)">{{ $t('search') }}</v-btn>
        </v-col>
        <v-col md="3" class="mb-3 py-0">
          <v-btn color="red" dark block @click="clearFilter">{{ $t('Delete Search') }}</v-btn>
        </v-col>
        <v-col md="3" class="mb-3 py-0">
          <ExportExcel end-point="/deposit-card-record/export" :params="filters"/>
          <!-- <v-btn color="blue" dark block  @click="exportToExcel(filters)"
            >{{ $t('hr.time.export') }}
            </v-btn> -->
        </v-col>
      </v-row>
  


      <v-container id="regular-tables" fluid tag="section">
      <v-simple-table :dark="$store.state.isDarkMode">
        <thead class="table-heading" v-if="!loading">
          <tr>
            <th v-for="(name, i) in tableHeaders" :key="i">
              {{ $t(name) }}
            </th>
          </tr>
        </thead>
        <div class="table__spinnerWrapper" v-if="loading">
          <app-spinner></app-spinner>
        </div>
        <v-fade-transition mode="out-in" :group="true" tag="tbody" v-if="!loading">
          <template   >
            
            <tr v-for="(item, idx) in tableItems" :key="idx">

              <td>{{ item.id }}</td>
              <td>{{ item.date }}</td>
              <td>{{ item.student }}</td>
              <td>{{ item.type }}</td>
              <td>{{ item.amount }}</td>
              <td>{{ item.username }}</td>
              <td>{{ item.desc }}</td>
           
              
            </tr>
          </template>
        </v-fade-transition>
      </v-simple-table>
      <div class="d-flex justify-end mt-5">
        <v-row>
          <v-col md="5 ">
            <v-switch
              @change="goToAll"    
              v-model="is_all"
              color="green"
              :label="$t('Show All')"
            ></v-switch>
          </v-col>
        <app-pagination v-if="(!loading & (paginated))" :disable-pagination="!paginated" :totalPages="total" :page="page"
          @PaginationValue="applyPagination($event)"></app-pagination>
        </v-row>
      </div>
    </v-container>
      <!-- <v-data-table 
        :headers="headers"
        :loading="loading"
        :items="tableItems"
        class="elevation-1 px-10"
        hide-default-footer
        :items-per-page="10"
      >
        <template v-slot:item.actions="{ item }">
          
        </template>
      </v-data-table>
      <v-pagination
        v-if="!loading && tableItems.length"
        @input="getAll"
        v-model="page"
        :length="Math.ceil(total / 10)"
      ></v-pagination>-->
    </div> 
  </template>
  
  <script>
  import { meal } from "../../../../store/auth";
  import axios from 'axios'
  import i18n from '@/i18n';
  import debounce from "../../../../helpers/debounce";
  import spinner from "../../../../components/base/spinner.vue";
  import pagination from "../../../../components/base/pagination.vue";
import { saveAs } from 'file-saver';
import ExportExcel from "../../components/core/ExportExcel.vue";

  
  export default {
    components: {
    appSpinner: spinner,
    appPagination: pagination,
    ExportExcel,

  },
    data: () => ({
      users: [],
      usersLoading: false,
      is_all: false,
      paginated: true,
      grades: null,
      gradesLoading: false,
      eduClasses: null,
      eduClassesLoading: false,
      childrens: null,
      childrensLoading: false,
      tableItems: [],
      meal,
      mealSearch: null,
      loading: false,
      page: 1,
      total: 1,
      tableHeaders: ["id","date", "student name", "type", "value", "user name","description"],
      // headers: [  
      //   { text: i18n.t("name"), value: "student" },
      //   { text: i18n.t('class'), value: "grade" },
      //   { text: i18n.t('educitional class'), value: "edu_class" },
      //   { text: i18n.t('Absent Type'), value: "type" },
      //   // { text: i18n.t('total credit'), value: "day" },
      //   { text: i18n.t('date'), value: "date" },
      //   { text: i18n.t('hr.employee.note'), value: "note" },
      // ],
      items: [],
    
      type: [
        {
          name: "شحن",
          value: "charge",
        },
        {
          name: "سحب",
          value: "pull",
        },
        {
          name: "شراء",
          value: "buy",
        },
        {
          name: "وجبة",
          value: "meal",
        },
        {
          name: "إرجاع",
          value: "return",
        },
      ],
      filters: {
        student_id: null,
        buffet_card: null,
        user_id: null,
        from: null,
        to: null,
        type: null,
      },
    }),
   
    methods: {
      goToAll(){
      if(this.is_all) {
        this.paginated = false;
      } else { this.paginated = true; }
      this.getAll();
    },
   
    async fetchRegRecordAutoComplete(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.childrensLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get("/student/auto-complete", {
            params: {
              name: searchValue,
              type: 'full_name',
            },
          });
          this.childrens = searchResult.data.data;
          
        } catch (err) {
          console.log("err", err);
        } finally {
          this.childrensLoading = false;
        }
      }, 1000)();
    },
     async exportToExcel(queryParams){
         
          try {
     
      const excel = await axios.get(`/deposit-card-record/export`, {
        params: {
          ...queryParams,
          
        },
        responseType: 'blob'
      });
      saveAs(excel.data)
      

    } catch (err) {
      console.log('err', err);
    } finally {
      
    }
      
      },
   
      clearFilter() {
        this.filters.student_id =  undefined;
        this.filters.from = undefined;
        this.filters.to = undefined;
        this.filters.buffet_card = undefined; 
        this.filters.user_id = undefined;
        this.filters.type = undefined;
        this.page = 1;
        this.getAll();
      },
      async fetchUsers(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.usersLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get("/user/auto-complete", {
            params: {
              name: searchValue,
            },
          });
          this.users = searchResult.data.users;
        } catch (err) {
          console.log("err", err);
        } finally {
          this.usersLoading = false;
        }
      }, 500)();
    },
      async getAll() {
        try {
         
          const filterTemp = {};
          Object.keys(this.filters).forEach((key) => {
            if (this.filters[key]) filterTemp[key] = this.filters[key];
          });
          this.loading = true;
          
          const res = await axios.get("/deposit-card-record/report", {
            params: {
              size: 10,
              page: this.page,
              paginated: this.paginated,
              ...filterTemp,
            },
          });
          const arr= Array.from(res.data.data)
          if(Array.isArray(arr))
          {
            console.log(arr);
            this.tableItems = arr.map((el) => {
              
              return {
                ...el,
                
              };
            });
          }
            console.log(res.data.data);
           
          this.total = res.data.meta.total;
          this.page = res.data.meta.current_page;
        } finally {
          this.loading = false;
        }
      },
      applyPagination(paginationValue) {
      this.page = paginationValue;
      this.getAll();
    },
     
 
    },
    async created() {
      this.getAll();
    
    },
  };
  </script>
  
  <style></style>
  